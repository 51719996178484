import React, { useRef } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { absolutify } from '../../utils/absolutify';

const Wrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &:not(.anim) {
        .inner {
            opacity: 0;
        }
    }
    .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 88%;
        font-size: 1.4rem;
        background: ${colors.dark_blue};
        padding: 30px;
        height: auto;
        transition: opacity 400ms ease-in-out;
        .cross {
            position: absolute;
            right: 5%;
            top: 5%;
            width: 25px;
            height: 25px;
            background: none;
            border: none;
            &:after,
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 48%;
                height: 27px;
                width: 1px;
                background: white;
                transform: rotate(45deg);
            }
            &:before {
                transform: rotate(-45deg);
            }
        }
        img {
            display: block;
            margin: 0 auto;
            width: 20%;
        }
        h2 {
            margin-top: 10px;
            text-align: center;
        }
        .job {
            text-align: center;
            font-family: 'Heebo';
            font-weight: 300;
        }
    }
    .categories {
        font-family: 'Heebo';
        .category {
            margin-top: 20px;
            h5 {
                font-weight: 500;
                margin-bottom: 8px;
            }
            li,
            .languages {
                font-weight: 300;
                line-height: 1.7;
            }
        }
    }
    @media screen and (min-width: 800px) {
        overflow-y: unset;
        .inner {
            width: 600px;
            padding: 30px 100px;
            padding-bottom: 70px;
            .job {
                line-height: 1.7;
            }
            img {
                width: 18%;
            }
            .cross {
                cursor: pointer;
            }
        }
    }
`;

const CollabModal = ({ className, data, closeModal, dataStrings, lang }) => {
    let self = useRef(null);

    setTimeout(() => {
        self.current.classList.add('anim');
    }, 100);

    function renderLanguages(languages) {
        let str = '';
        languages.forEach((language, i) => {
            str += language.language;
            if (i + 1 !== languages.length) {
                str += ' - ';
            }
        });
        return str;
    }

    function handleClose() {
        closeModal();
    }

    function handleOutsideClick(event) {
        if (event.target.id) {
            handleClose();
        }
    }

    return (
        <Wrapper
            id="container"
            onClick={handleOutsideClick}
            ref={self}
            className={className}
        >
            <div className="inner">
                <button className="cross" onClick={handleClose} />

                <img
                    alt={data.title}
                    src={absolutify(data.acfSingleCollaborator.bitmoji.url)}
                />

                <h2 className="title-2">{data.title}</h2>
                <p className="job">{data.acfSingleCollaborator.post}</p>
                <ul className="categories">
                    <li>
                        <ul className="category">
                            <h5>
                                {lang === 'FR'
                                    ? dataStrings.team_label_formations.fr
                                    : dataStrings.team_label_formations.en}{' '}
                                :
                            </h5>
                            {data.acfSingleCollaborator.educations.map(
                                (item, i) => (
                                    <li key={i}>• {item.education}</li>
                                ),
                            )}
                        </ul>
                    </li>
                    <li>
                        <ul className="category">
                            <h5>
                                {lang === 'FR'
                                    ? dataStrings.team_label_experiences.fr
                                    : dataStrings.team_label_experiences
                                          .en}{' '}
                                :
                            </h5>
                            {data.acfSingleCollaborator.experiences.map(
                                (item, i) => (
                                    <li key={i}>• {item.experience}</li>
                                ),
                            )}
                        </ul>
                    </li>
                    <li>
                        <ul className="category">
                            <h5>
                                {lang === 'FR'
                                    ? dataStrings.team_label_languages.fr
                                    : dataStrings.team_label_languages.en}{' '}
                                :
                            </h5>
                            <p className="languages">
                                {renderLanguages(
                                    data.acfSingleCollaborator.languages,
                                )}
                            </p>
                        </ul>
                    </li>
                </ul>
            </div>
        </Wrapper>
    );
};

export default CollabModal;
