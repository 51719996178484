import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { absolutify } from '../../utils/absolutify';
import CollabModal from './CollabModal';

const mobile = '(max-width: 799px)';
const desktop = '(min-width: 800px)';

/*
La toile d'araignée se construit sur un pattern de 10 points.
Les 2 premiers ne comptent pas.
S'ensuit un pattern constitué de :
- 1 ligne de 3
- 1 ligne de 2
- 1 ligne de 3
- 1 ligne de 2
Soit 10 en tout.
Les lignes partent toujours vers le haut : ouest(o), nord-ouest (no), nord (n) et nord-est (ne).
Comme ça aucun risque d'avoir une ligne orpheline.
Et on gère l'apparition de ces lignes via les arrays suivants.
Si c'est 0, la ligne est masquée.
Si c'est 1 la ligne est droite.
Autre que 0 ou 1, c'est l'angle de brisure de la ligne (et la taille de la boule).
*/
const lines = {
    o: [0, 1, 0, 0, 15, 0, 0, 0, 0, 1, 0, -8, 0, 0, -11],
    no: [0, -19, 1, 1, 0, 0, 19, 0, -11, 0, 0, -12, 1, 31, 1],
    n: [0, 0, 0, 23, 1, -17, 1, -20, 0, 13, 0, -19, 11, 1, 0],
    ne: [-7, 11, 0, 0, 1, 1, 1, 0, 1, 1, 12, 0, 0, 1, 1],
};

const ratioWidth = 3;
const ratioHeight = 1;
const angle = Math.atan(ratioWidth / 2 / ratioHeight);
const hypotenuseLength = 1 / Math.cos(angle);

const Wrapper = styled.div/* CSS */ `
    --columns-size: calc(100% / 2);
    --img-width: 33%;

    grid-column: 1/4;
    margin-top: 80px;
    z-index: 3;

    @media ${desktop} {
        --columns-size: calc(100% / 3);
        --img-width: 20%;

        grid-column: 4/12;
        margin-top: 300px;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        color: ${colors.pink};
        z-index: 1;
    }

    .item {
        position: relative;
        width: var(--columns-size);

        /* décalage une ligne sur deux */
        @media ${mobile} {
            &:nth-child(3n - 2) {
                margin-left: 0.01%;
            }
        }
        @media ${desktop} {
            &:nth-child(5n - 4) {
                margin-left: 0.01%;
            }
        }

        /* La taille des boutons se fait par le ratio 
        (les img se redimensionnent selon le conteneur et non l'inverse) */
        button {
            display: block;
            width: 100%;
            height: 0;
            padding: 0 0 ${(ratioHeight / ratioWidth) * 100}% 0;
            background: none;
            border: none;
            cursor: pointer;
        }

        /* La taille de l'img se fait selon la width du parent */
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: var(--img-width);
            margin: auto;
            object-fit: contain;
            transition: 0.2s transform;
        }

        button:hover img {
            transform: scale(1.2);
        }
    }

    /* Les fillers servent à garder un bon alignement des items qu'importe leur nombre */
    .filler {
        @media ${mobile} {
            &.desktop {
                display: none;
            }
        }
        @media ${desktop} {
            &.mobile {
                display: none;
            }
        }
    }

    .lineGroup {
        position: absolute;
        left: 50%;
        bottom: 50%;
        z-index: -1;
        transform-origin: center bottom;

        @media ${mobile} {
            display: none;
        }
    }

    .no {
        height: ${hypotenuseLength * 100}%;
        transform: rotate(-${angle}rad);
    }

    .n {
        height: 200%;
    }

    .ne {
        height: ${hypotenuseLength * 100}%;
        transform: rotate(${angle}rad);
    }

    .o {
        height: ${(ratioWidth * 100) / ratioHeight}%;
        transform: rotate(-90deg);
    }

    .line1,
    .line2 {
        position: absolute;
        left: 0;
        width: 1px;
        height: calc(var(--brisure-length, 1) * 50%);
        background-color: currentColor;
    }

    .line1 {
        bottom: 0;
        transform: rotate(calc(var(--brisure-angle, 0) * -1rad));
        transform-origin: center bottom;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: var(--bouboule-size, 0);
            height: var(--bouboule-size, 0);
            border-radius: 50%;
            background-color: currentColor;
            transform: translate(-50%, -50%);
        }
    }

    .line2 {
        top: 0;
        transform: rotate(calc(var(--brisure-angle, 0) * 1rad));
        transform-origin: center top;
    }
`;

function getBrisure(angle = 0) {
    if (angle === 1) {
        return { '--brisure-angle': 0, '--brisure-length': 1 };
    } else {
        const brisureAngle = angle * (Math.PI / 180); // en radians
        const brisureLength = 1 / Math.cos(brisureAngle);
        return {
            '--brisure-angle': brisureAngle,
            '--brisure-length': brisureLength,
            '--bouboule-size': Math.min(20, Math.abs(angle)) + 'px',
        };
    }
}

const TeamList = ({ className, data: list, dataStrings, lang }) => {
    const [modalData, setModalData] = useState(null);

    const hasFillerDesktop =
        !((list.length + 1) % 5) || !((list.length + 4) % 5);
    const hasFillerMobile = !((list.length + 1) % 3);

    return (
        <Wrapper className={className}>
            <ul className="list">
                {list.map(({ node }, i) => {
                    const src = node.acfSingleCollaborator.bitmoji.url;

                    const index = (i - 2) % lines.n.length;
                    const lineNO = lines.no[index];
                    const lineN = lines.n[index];
                    const lineNE = lines.ne[index];
                    const lineO = lines.o[index];

                    return (
                        <li key={i} title={node.title} className="item">
                            <button onClick={() => setModalData(node)}>
                                <img alt="" src={absolutify(src)} />
                            </button>

                            {!!lineNO && (
                                <div
                                    style={getBrisure(lineNO)}
                                    className="lineGroup no"
                                >
                                    <div className="line1" />
                                    <div className="line2" />
                                </div>
                            )}
                            {!!lineN && (
                                <div
                                    style={getBrisure(lineN)}
                                    className="lineGroup n"
                                >
                                    <div className="line1" />
                                    <div className="line2" />
                                </div>
                            )}
                            {!!lineNE && (
                                <div
                                    style={getBrisure(lineNE)}
                                    className="lineGroup ne"
                                >
                                    <div className="line1" />
                                    <div className="line2" />
                                </div>
                            )}
                            {!!lineO && (
                                <div
                                    style={getBrisure(lineO)}
                                    className="lineGroup o"
                                >
                                    <div className="line1" />
                                    <div className="line2" />
                                </div>
                            )}
                        </li>
                    );
                })}

                {hasFillerDesktop && <li className="item filler desktop" />}
                {hasFillerMobile && <li className="item filler mobile" />}
            </ul>

            {modalData && (
                <CollabModal
                    lang={lang}
                    data={modalData}
                    dataStrings={dataStrings}
                    closeModal={() => setModalData(null)}
                />
            )}
        </Wrapper>
    );
};

export default TeamList;
