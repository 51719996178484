import { graphql } from 'gatsby';
import React from 'react';
import DoubleColumnText from '../components/common/DoubleColumnText';
import FooterMoutains from '../components/common/FooterMountains';
import Header from '../components/common/Header';
import Push from '../components/common/Push';
import VerticalLine from '../components/common/VerticalLine';
import Layout from '../components/layout';
import SEO from '../components/SEO/SEO';
import TeamList from '../components/team/TeamList';

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Team" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageTeam {
                        header {
                            subtitle
                            title
                            type
                        }
                        double_column_text {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        push {
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                    }
                }
            }
        }
        allWpCollaborator(filter: { language: { code: { eq: $lang } } }) {
            edges {
                node {
                    acfSingleCollaborator {
                        bitmoji {
                            filesize
                            height
                            mime_type
                            title
                            url
                            width
                        }
                        educations {
                            education
                        }
                        experiences {
                            experience
                        }
                        languages {
                            language
                        }
                        post
                    }
                    title
                }
            }
        }
        wp {
            sp {
                strings {
                    team_label_formations {
                        en
                        fr
                    }
                    team_label_experiences {
                        en
                        fr
                    }
                    team_label_languages {
                        en
                        fr
                    }
                }
            }
        }
    }
`;

const TeamPage = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    let dataTeam = data.allWpPage.edges[0].node.acfPageTeam;
    let dataCollaborator = data.allWpCollaborator.edges;
    let dataStrings = data.wp.sp.strings;
    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header data={dataTeam.header} />
            <DoubleColumnText data={dataTeam.double_column_text} />
            <TeamList
                data={dataCollaborator}
                dataStrings={dataStrings}
                lang={pageContext.lang}
            />
            <VerticalLine />
            <Push data={dataTeam.push} />
            <FooterMoutains />
        </Layout>
    );
};

export default TeamPage;
